<template>
  <v-card class="mt-0" height="100%" max-height="500px">

    <v-layout fill-height class="d-flex flex-column">
      <v-toolbar color="transparent elevation-0">
        <v-toolbar-title>
          Exclusion des clients
        </v-toolbar-title>
      </v-toolbar>

      <vue-scroll>

        <v-card-text>
      <v-dialog v-model="showModal"  width="50%">
        <template v-slot:activator="{attr, on}">
          <v-btn color="success" v-bind="attr" v-on="on">Créer une liste</v-btn>
        </template>
        <v-card>
          <v-card-title>
            Créer une liste d'exclusion
          </v-card-title>

          <v-card-text>
            <v-text-field v-model="title" label="Titre de la liste" :rules="ValidationRules.required"></v-text-field>

            <client-picker v-model="creatingList"></client-picker>

          </v-card-text>
          <v-card-actions>
            <v-btn :color="editing ? 'warning' : 'success'" @click="add" :loading="sending">{{ editing ? "Modifier" : "Créer" }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-list>
        <v-list-item v-for="(list, i) in exclusionLists" :key="i">
          <v-list-item-content>
            <v-list-item-title class="d-flex flex-row align-center">
              {{ list.title }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
          <v-switch v-model="list.active" class="pa-0 ma-0" @change="toggleActive(list)">
            <template v-slot:label ><div class="ml-4 mr-16">Actif</div></template>
          </v-switch>
          </v-list-item-action>
          <v-list-item-action class="d-flex flex-row align-center my-2">
            <v-btn icon @click="editList(list)">
              <v-icon>mdi-square-edit-outline</v-icon>
            </v-btn>
            <v-btn icon @click="confirmRemoveList(list)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card-text>

      </vue-scroll>
    </v-layout>


    <v-dialog
        v-model="confirmDeleteList"
        max-width="480"
    >
      <v-card v-if="listDeleting">
        <v-card-title>Confirmation de suppression</v-card-title>
        <v-card-text>
          Voulez-vous vraiment supprimer cette liste ?<br><br>
          <v-icon color="warning" small>mdi-alert</v-icon>
          En supprimant cette liste, les bases associées veront de nouveau les communications (et ce instantanément).<br><br>
          Voulez-vous continuer ?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="listDeleting = null;confirmDeleteList = false">Annuler</v-btn>
          <v-btn color="error" @click="remove(listDeleting)">Supprimer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


<!--    <v-dialog-->
<!--        v-model="confirmActivingList"-->
<!--        max-width="480"-->
<!--    >-->
<!--      <v-card v-if="listActiving">-->
<!--        <v-card-title>Confirmation de restrictions</v-card-title>-->
<!--        <v-card-text>-->
<!--          Voulez-vous vraiment activer cette liste maintenant ?<br>-->
<!--          Les utilisateurs des bases de cette ne verront plus les communications imminemment.<br>-->
<!--        </v-card-text>-->
<!--        <v-card-actions>-->
<!--          <v-spacer></v-spacer>-->
<!--          <v-btn text @click="listActiving = null;confirmActivingList = false">Annuler</v-btn>-->
<!--          <v-btn color="error" @click="toggleActive(listActiving)">Désactiver</v-btn>-->
<!--        </v-card-actions>-->
<!--      </v-card>-->
<!--    </v-dialog>-->
  </v-card>
</template>

<script>
import CommunicationService from "@/Services/Communication";
import PlaneteOnlineService from "@/Services/PlaneteOnlineService";
import ValidationRules from "@/Mixins/ValidationRules"

export default {
  name: "ClientExclusion",
  mixins: [ValidationRules],
  components: {
    ClientPicker: () => import("@/Components/Commons/Inputs/Client/ClientPicker")
  },
  computed: {
    total() {
      return this.creatingList.length
    }
  },
  data() {
    return {
      showModal: false,
      exclusionLists: [],
      clientList_: [], // Default globle list (unmodifiable)
      creatingList: [],
      title: "",
      loadingClients: true,
      sending: false,
      confirmDeleteList: false,
      confirmActivingList: false,
      listDeleting: null,
      listActiving: null,
      uuid: null,
      editing: false,
      tmpId: null
    }
  },
  methods: {
    editList(list) {
      this.editing = true
      this.tmpId = list._id
      this.clientList_.forEach((client,i) => {
        client.children.forEach(child => {
          child.selected = false
        })
        client.selected = false
      })

      this.creatingList = list.list
      this.title = list.title
      let alreadySelected = this.creatingList.map(a => a.name)
      this.clientList_.forEach((client,i) => {
        let countSelected = 0
        client.children.forEach(child => {
          if (alreadySelected.indexOf(child.name) !== -1) {
            child.selected = true
            countSelected++
          }
        })
        if (countSelected == client.children.length) {
          client.selected = true
        }
      })

      this.uuid = this.$uuid.v4()
      this.showModal = true
    },
    cancelEditList() {
      this.showModal = false
      this.creatingList = []
    },
    addItem(items) {
      let compare = this.creatingList.map(a => a.name)
      for (let item of items) {
        if (compare.indexOf(item.name) === -1) {
          this.creatingList.push(item)
        }
      }
    },
    removeItem(items) {
      for (let item of items) {
        let index = this.creatingList.findIndex(a => a.name == item.name)
        if (index !== -1) {
          this.creatingList.splice(index, 1)
        }
      }
    },
    async add() {
      this.sending = true
      if (this.title) {
        let list = {
          title: this.title,
          list: this.creatingList,
          active: true
        }

        if (!this.editing) {
          let addResponse = await CommunicationService.postExclusionList(list)
          if (addResponse) {
            this.exclusionLists.push(addResponse)
            this.showModal = false
            this.$nSuccess("Liste d'exclusion créée !")
          } else {
            this.$nError("Impossible de créer la liste d'exclusion")
          }
        } else {
          this.editing = false
          list._id = this.tmpId
          let addResponse = await CommunicationService.putExclusionList(list)
          if (addResponse) {
            let ind = this.exclusionLists.findIndex(a=>a._id == list._id)
            this.exclusionLists.splice(ind, 1, list)
            this.showModal = false
            this.$nSuccess("Liste d'exclusion modifiée !")
          } else {
            this.$nError("Impossible de modifier la liste d'exclusion")
          }
        this.tmpId = null
        }
      } else {
        this.$nError("Vous devez renseigner un titre")
      }
      this.sending = false
    },
    async toggleActive(list) {
      let response = await CommunicationService.putExclusionList(list)
    },
    async remove(list) {
      let deleted = CommunicationService.removeExclusionList(list._id)
      if (deleted) {
        let index = this.exclusionLists.findIndex(a => a._id == list._id)
        this.exclusionLists.splice(index, 1)
      }
      this.confirmDeleteList = false
      this.listDeleting = null

    },
    confirmRemoveList(list) {
      this.listDeleting = list
      this.confirmDeleteList = true
    },
    confirmActiveList(list) {
      this.listActiving = list
      this.confirmActivingList = true
    },
  },
  async mounted() {

    this.clientList_ = await PlaneteOnlineService.getBases(true)
        // bases.forEach(a => {
        //   let children = a.bases.map(b => {return {name: b}} )
        //   this.$set(a, "children", children)
        // })
    // bases = bases.filter(a => a.name)
    // bases.sort((a, b) => a.name.localeCompare(b.name))
    this.loadingClients = false

    this.exclusionLists = await CommunicationService.getExclusionList()
  },
  watch: {
    showModal() {
      if (!this.showModal) {
          this.clientList_.forEach(client => {
            client.children.forEach(child => {
              child.selected = false
            })
            client.selected = false
          })
      }
    }
  }
}
</script>

<style scoped>
  .max-height-600 {
    max-height: 350px;
  }
</style>