const mixin = {
  computed: {
    validation() {
      let $this = this
      return {
        min: n => {
          return v => (v && v.length >= n) || $this.$t("validation.min", {val: n})
        },
        max: n => {
          return v => (v && v.length <= n) || $this.$t("validation.max", {val: n})
        },
        required: v => !!v || this.$t("validation.required"),
        regex: () => {
          return {
            mail: v => !v || v.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) != null || $this.$t("validation.mail")
          }
        },
        contains(rules) {
          let returnRules = []

          if (rules.uppercase) {
            let min = 1
            let max = 0
            if (rules.uppercase.min) {
              min = rules.uppercase.min
            }
            if (rules.uppercase.max) {
              max = rules.uppercase.max
            }
            if (max == 0) {
              returnRules.push(v => v && (v.match(/[A-Z]/g) || []).length >= min || $this.$t("validation.containsUpperCaseMin", {min}))
            } else if (min == 0) {
              returnRules.push(v => v && (v.match(/[A-Z]/g) || []).length <= max || $this.$t("validation.containsUpperCaseMax", {max}))
            } else {
              returnRules.push(v => v && (v.match(/[A-Z]/g) || []).length >= min && (v.match(/[A-Z]/g) || []).length <= max|| $this.$t("validation.containsUpperCaseBetween", {min, max}))
            }
          }
          if (rules.number) {
            let min = 1
            let max = 0
            if (rules.number.min) {
              min = rules.number.min
            }
            if (rules.number.max) {
              max = rules.number.max
            }
            if (max == 0) {
              returnRules.push(v => v && (v.match(/[0-9]/g) || []).length >= min || $this.$t("validation.containsNumberMin", {min}))
            } else if (min == 0) {
              returnRules.push(v => v && (v.match(/[0-9]/g) || []).length <= max || $this.$t("validation.containsNumberMax", {max}))
            } else {
              returnRules.push(v => v && (v.match(/[0-9]/g) || []).length >= min && (v.match(/[0-9]/g) || []).length <= max || $this.$t("validation.containsNumberBetween", {min, max}))
            }
          }
          if (rules.letter) {
            let min = 1
            let max = 0
            if (rules.letter.min) {
              min = rules.letter.min
            }
            if (rules.letter.max) {
              max = rules.letter.max
            }
            if (max == 0) {
              returnRules.push(v => v && (v.match(/[a-zA-Z]/g) || []).length >= min || $this.$t("validation.containsAlphaMin", {min}))
            } else if (min == 0) {
              returnRules.push(v => v && (v.match(/[a-zA-Z]/g) || []).length <= min || $this.$t("validation.containsAlphaMax", {max}))
            } else {
              returnRules.push(v => v && (v.match(/[a-zA-Z]/g) || []).length >= min && (v.match(/[a-zA-Z]/g) || []).length <= max|| $this.$t("validation.containsAlphaBetween", {min, max}))
            }
          }
          if (rules.lowercase) {
            let min = 1
            let max = 0
            if (rules.lowercase.min) {
              min = rules.lowercase.min
            }
            if (rules.lowercase.max) {
              max = rules.lowercase.max
            }
            if (max == 0) {
              returnRules.push(v => v && (v.match(/[a-z]/g) || []).length >= min || $this.$t("validation.containsLowerCaseMin", {min}))
            } else if (min == 0) {
              returnRules.push(v => v && (v.match(/[a-z]/g) || []).length <= max || $this.$t("validation.containsLowerCaseMax", {max}))
            } else {
              returnRules.push(v => v && (v.match(/[a-z]/g) || []).length >= min && (v.match(/[a-z]/g) || []).length <= max|| $this.$t("validation.containsLowerCaseBetween", {min, max}))
            }
          }
          if (rules.specialchar) {
            let min = 1
            let max = 0
            if (rules.specialchar.min) {
              min = rules.specialchar.min
            }
            if (rules.specialchar.max) {
              max = rules.specialchar.max
            }
            if (max == 0) {
              returnRules.push(v => v && (v.match(/[/\\*`|]/g) || []).length >= min || $this.$t("validation.containsSpecialCharsMin", {min}))
            } else if (min == 0) {
              returnRules.push(v => v && (v.match(/[/\\*`|]/g) || []).length <= max || $this.$t("validation.containsSpecialCharsMax", {max}))
            } else {
              returnRules.push(v => v && (v.match(/[/\\*`|]/g) || []).length >= min && (v.match(/[/\\*`|]/g) || []).length <= max || $this.$t("validation.containsSpecialCharsBetween", {min, max}))
            }
          }

          // Retourne le résultat de l'exécution de toutes les fonctions et ne garde que les messages d'erreurs s'il y en a, true sinon
          return v => returnRules.map(b => b(v)).filter(a=>a!==true).shift() || true

        }
      }
    },
    ValidationRules() {
      return {
        login: [
          this.validation.required,
        ],
        password: [
            this.validation.required,
            this.validation.contains({
              number: true,
              lowercase: true,
              uppercase: true
            }),
            this.validation.min(8),
        ],
        title: [
          this.allValidationRules.excludeSpecialChars,
          this.allValidationRules.excludeNumbers
        ],
        username: [
          this.validation.required,
          this.allValidationRules.excludeSpecialChars,
        ],
        email: [
          this.validation.regex().mail
        ],
        required: [
          this.validation.required
        ],
        wallpaper: [
          this.allValidationRules.maxFileSize2mb,
          this.allValidationRules.typeImage
        ],
        photo: [
          this.allValidationRules.maxFileSize2mb,
          this.allValidationRules.typeImage
        ],
        signature: [
          this.allValidationRules.maxFileSize2mb,
          this.allValidationRules.typeImage
        ]
      }
    }
  },
  data() {
    return {
      allValidationRules: {

        containsAlpha: v => v && (v.match(/[a-zA-Z]/g) || []).length >= 1 || this.$t("validation.containsAlpha"),
        containsNumber: v => v && (v.match(/[0-9]/g) || []).length >= 2 || this.$t("validation.containsNumber"),
        containsUpperCase: v => v && (v.match(/[A-Z]/g) || []).length >= 2 || this.$t("validation.containsUpperCase"),
        containsLowerCase: v => v && (v.match(/[a-z]/g) || []).length >= 2 || this.$t("validation.containsLowerCase"),
        onlyAlpha: v => v && v.match(/^[a-zA-Z]+$/) != null || this.$t("validation.onlyAlpha"),
        onlyNumeric: v => v && v.match(/^[0-9]$/) || this.$t("validation.onlyNumeric"),
        onlyUpperCase: v => v && v.match(/^[a-z]$/) || this.$t("validation.onlyUpperCase"),
        onlyLowerCase: v => v && v.match(/^[A-Z]$/) || this.$t("validation.onlyLowerCase"),
        excludeSpecialChars: v => v ? v.match(/[/\\*`|]/g) == null || this.$t("validation.excludeSpecialChars") : "",
        excludeNumbers: v => v ? v.match(/[0-9]+/g) == null || this.$t("validation.excludeNumbers") : "",
        maxFileSize2mb: v => !v || v.size <= 2e6 || this.$t("validation.maxFileSize2mb"),
        typeImage: v => !v || (v.type.match("image/.*")||[]).length > 0 || this.$t("validation.fileTypeImage"),
      }
    }
  },
  // {
  //   required: true,
  //   string: {
  //     length: {
  //       min: 1,
  //       max: 8
  //     },
  //     containsAlpha: {
  //       min: 1,
  //       max: 3
  //     },
  //     containsNumber: {
  //       min: 1,
  //       max: 2
  //     },
  //     containsUppercase: true,
  //     containsLowercase: true,
  //         only: {}
  //   },
  //   numeric: {
  //     min: 0,
  //     max: 1000,
  //     prime: true,
  //     odd: true,
  //     even: true
  //   },
  //   file: {
  //     size: {
  //       min: 1000,
  //       max: 2000000
  //     },
  //     type: {
  //       accept: "image/*"
  //     }
  //   }
  // }

}

export default mixin
